import { Box, Image, Text } from '@chakra-ui/react';
import WalmartLogo from '../assets/images/WM-Realm Logo-Latest.png';
import RegionLockedBG from '../assets/images/RegionLockedBG.jpg';
import RegionLockedBGMobile from '../assets/images/RegionLockedBGMobile.jpg';
import React from 'react';
import useWindowDimensions from '../utils/useWindowDimensions';

interface RegionNotAllowedProps {
  active: boolean;
}

function RegionNotAllowed({ active }: RegionNotAllowedProps) {
  const { width } = useWindowDimensions();

  return (
    <Box
      opacity={active ? 1 : 0}
      visibility={active ? 'visible' : 'hidden'}
      transition="all 0.2s ease-in-out"
      width="100%"
      height="100vh"
      position="fixed"
      userSelect="none"
    >
      <Image
        src={width < 769 ? RegionLockedBGMobile : RegionLockedBG}
        position="absolute"
        top={0}
        bottom={0}
        objectFit="cover"
        height="100%"
        width="100%"
      />
      <Box
        position="absolute"
        gap="20px"
        padding="20px"
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={WalmartLogo}
          objectFit="contain"
          alt="walmart logo"
          loading="lazy"
          width={['220px', '220px', '220px', '220px', '220px']}
          mb={['32px']}
        />

        <Text
          fontSize={['18px', '18px', '18px', '18px', '18px']}
          lineHeight={['20px', '20px', '20px', '20px', '20px']}
          color="white"
          fontFamily="EverydaySans-Regular-Web"
          textAlign="center"
        >
          Thank you for your interest in Walmart Realm. This experience is not available in your country/region at this
          time.
        </Text>
        <Text
          fontSize={['18px', '18px', '18px', '18px', '18px']}
          lineHeight={['20px', '20px', '20px', '20px', '20px']}
          color="white"
          fontFamily="EverydaySans-Regular-Web"
          textAlign="center"
        >
          Gracias por tu interés en Walmart Realm. Esta experiencia no está disponible en tu país o región en este
          momento.
        </Text>
      </Box>
    </Box>
  );
}

export default RegionNotAllowed;
