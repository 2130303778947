import React from 'react';
import { Helmet } from 'react-helmet';

const MatomoTrackingScript = () => {
  return (
    <Helmet>
      <script>
        {`var _paq = (window._paq = window._paq || []);
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            _paq.push(['disableCookies']);
            (function () {
            var u = "//walmart-analytics.emperia-staging.com/";
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            // For staging siteId is '1', for production its '2'
            _paq.push(['setSiteId', '1']);
            var d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.async = true;
            g.src = u + 'matomo.js';
            s.parentNode.insertBefore(g, s);
            })();`}
      </script>
      <script>
        {` var _mtm = (window._mtm = window._mtm || []);
            _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
            (function () {
                var d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
                g.async = true;
                g.src = 'https://walmart-analytics.emperia-staging.com/js/container_6wmuwFwl.js';
                s.parentNode.insertBefore(g, s);
            })();`}
      </script>
      ßß
    </Helmet>
  );
};

export default MatomoTrackingScript;
