import { Box, Button, Divider, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { CartItemProps, SelectedVariantTypeState } from '../../interfaces/components';
import Counter from './Counter';
import { Trash } from '../../Icons/Trash';
import React from 'react';
import { debounce } from '../../utils';
import { Heart } from '../../Icons/Heart';

function CartItem({ cartItem, onItemRemove, updateCart, addToWishlist }: CartItemProps) {
  const { quantity, id, imageSrc, name, price, selectedVariantAttributes } = cartItem;
  const [productCount, setProductCount] = useState<number>(quantity);

  const debouncedUpdateCart = debounce(updateCart, 1000);

  const onIncrease = () => {
    setProductCount((state) => ++state);
    debouncedUpdateCart(id, productCount + 1);
  };

  const onDecrease = () => {
    if (productCount > 1) {
      setProductCount((state) => --state);
      debouncedUpdateCart(id, productCount - 1);
    }
  };

  return (
    <Box>
      <Box w="100%" h="auto" display="flex" flexDirection="column" gap="20px" key={id} padding="20px 0px">
        <Box display="flex" gap="3">
          <Image src={imageSrc} w="130px" h="auto" alt="product" objectFit="cover" />
          <Box display="flex" flexDirection="column" gap="10px">
            <Text
              fontSize={['14px', '14px']}
              lineHeight={['18px', '18px']}
              fontFamily="Bogle"
              textAlign="left"
              color="#041F41;"
            >
              {name}
            </Text>

            {selectedVariantAttributes &&
              Object.keys(selectedVariantAttributes as SelectedVariantTypeState)?.length > 0 &&
              Object.keys(selectedVariantAttributes as SelectedVariantTypeState)?.map((key: string) => (
                <Box display="flex" gap="5px" key={key}>
                  <Text fontSize={['14px', '14px']} lineHeight={['18px', '18px']} fontFamily="Bogle" textAlign="left">
                    {(selectedVariantAttributes as SelectedVariantTypeState)[parseInt(key)]?.type || 'Unknown Type'}:
                  </Text>
                  <Text fontSize={['14px', '14px']} lineHeight={['18px', '18px']} fontFamily="Bogle" textAlign="left">
                    {(selectedVariantAttributes as SelectedVariantTypeState)[parseInt(key)]?.value || 'Unknown Value'}
                  </Text>
                </Box>
              ))}
          </Box>
        </Box>

        <Box display="flex" p="0px 10px" flexDirection="column" alignItems="flex-end" gap={2}>
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <Counter count={productCount} onIncrease={onIncrease} onDecrease={onDecrease} />
            <Text fontSize={['18px', '18px']} lineHeight={['24px', '24px']} fontFamily="Bogle" textAlign="right">
              ${(productCount * price).toFixed(2)}
            </Text>
          </Box>

          <Button
            rightIcon={<Heart stroke="#757575" boxSize={[4, 4, 5]} />}
            variant="link"
            fontSize={['12px', '12px']}
            lineHeight={['16px', '16px']}
            fontFamily="Bogle"
            textAlign="right"
            color="#757575"
            onClick={addToWishlist}
          >
            Save for later
          </Button>

          <Button
            rightIcon={<Trash boxSize={[4, 4, 5]} />}
            variant="link"
            fontSize={['12px', '12px']}
            lineHeight={['16px', '16px']}
            fontFamily="Bogle"
            textAlign="right"
            color="#757575"
            onClick={onItemRemove}
          >
            Remove
          </Button>
        </Box>
      </Box>

      <Divider orientation="horizontal" borderColor="#C2C2C2" />
    </Box>
  );
}

export default CartItem;
