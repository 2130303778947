import { useEffect } from 'react';

const loadGTM = (gtmId: string) => {
  if (!gtmId || document.getElementById('gtm-script')) return;

  const script = document.createElement('script');
  script.id = 'gtm-script';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
};

const GTM = ({ gtmId }: { gtmId: string }) => {
  useEffect(() => {
    loadGTM(gtmId);
  }, [gtmId]);

  return null;
};

export default GTM;
