import { Box, useToast, Text, UseToastOptions, Button } from '@chakra-ui/react';
import React from 'react';
import { ReactNode } from 'react';
import { Close } from '../../Icons/Close';

interface CustomToastProps extends UseToastOptions {
  title: string;
  description?: string;
  status?: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
  icon?: ReactNode;
  bottom?: string[];
  buttonText?: string;
  buttonHandler?: () => void;
}

export const useCustomToast = () => {
  const toast = useToast();

  return ({
    title,
    status = 'info',
    duration = 2000,
    position = 'bottom',
    bottom,
    buttonText,
    buttonHandler,
  }: CustomToastProps) => {
    // if (id && toast.isActive(id)) return;

    toast({
      // id,
      position,
      duration,
      isClosable: true,
      containerStyle: {
        display: 'flex',
        justifyContent: 'center',
        bottom: bottom,
        position: 'relative',
      },
      render: ({ onClose }) => (
        <Box
          bg={statusColor(status)}
          padding="16px 24px"
          color="white"
          p={3}
          height="auto"
          minHeight="40px"
          width="280px"
          border="1px solid #FFFFFF4D"
          borderRadius="8px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
          zIndex="999999"
        >
          <Text
            fontFamily="EverydaySans-Semibold-Web"
            fontSize={['14px', '14px']}
            color="white"
            textAlign="left"
            wordBreak="break-word"
          >
            {title}
          </Text>

          {buttonText && buttonText?.length > 0 && (
            <Button
              variant="link"
              color="white"
              fontFamily="EverydaySans-Semibold-Web"
              textDecoration="underline"
              fontSize={['14px', '14px']}
              onClick={() => {
                buttonHandler && buttonHandler();
                onClose();
              }}
            >
              view
            </Button>
          )}

          <Close stroke="white" boxSize={[3]} onClick={onClose} cursor="pointer" />
        </Box>
      ),
    });
  };
};

const statusColor = (status: 'success' | 'error' | 'warning' | 'info') => {
  switch (status) {
    case 'success':
      return '#041F4199';
    case 'error':
      return '#041F4199';
    case 'warning':
      return '#041F4199';
    case 'info':
      return '#041F4199';
    default:
      return '#041F4199';
  }
};
